import React from "react";
import AI2_PRIOR_LOGO from "~icons/built-by-partial.svg";
import AI2_PRIOR_GEAR from "~icons/ai2-prior-gear.svg";
import { css } from "@emotion/react";
import color from "~styles/color";
import { EmailSubscription } from "~components/email-subscribe";

function PRIORLogo() {
  return (
    <div
      css={css`
        position: relative;
        border-image: linear-gradient(270deg, ${color.dark.blue9}, transparent)
          100% 0;
        border-width: 0 1px 1px;
        border-style: solid;
        padding-bottom: 15px;
        text-align: right;
      `}
    >
      <div
        css={css`
          display: inline-block;
          position: relative;
        `}
      >
        <a
          href="//prior.allenai.org/"
          target="_blank"
          rel="noreferrer"
          className="noselect"
        >
          <img
            src={AI2_PRIOR_GEAR}
            alt="AI2 PRIOR Gear"
            css={css`
              height: 20px;
              position: absolute;
              @media (max-width: 400px) {
                height: 16px;
                right: 14px;
              }

              right: 6.8%;
              bottom: 0px;
              animation: rotateWheel 5s infinite cubic-bezier(0, 1, 1, 0);

              @keyframes rotateWheel {
                0% {
                  transform: rotate(0deg);
                }
                50% {
                  transform: rotate(180deg);
                }
                100% {
                  transform: rotate(359.9deg);
                }
              }
            `}
          />
          <img
            src={AI2_PRIOR_LOGO}
            alt="AI2 PRIOR Logo"
            css={css`
              width: 250px;
              @media (max-width: 400px) {
                width: 200px;
              }
            `}
          />
        </a>
      </div>
    </div>
  );
}

export default function Footer(props: { preventMaxWidth?: boolean }) {
  return (
    <>
      <footer
        css={css`
          background-color: ${color.dark.blue1};
          overflow-x: hidden;
        `}
      >
        <div
          css={css`
            margin: auto;
            padding-top: 20px;
            height: 150px;
            @media (max-width: 1150px) {
              width: 100%;
              padding-right: 30px;
              padding-left: 30px;
            }

            @media (max-width: 400px) {
              padding-right: 10px;
              padding-left: 10px;
            }
            max-width: ${props.preventMaxWidth ? "none" : "1200px"};
            padding-left: ${props.preventMaxWidth ? "70px" : ""};
            padding-right: ${props.preventMaxWidth ? "70px" : ""};
          `}
        >
          <div
            css={css`
              float: right;
              width: 50%;
              @media (max-width: 1150px) {
                width: 100%;
              }
            `}
          >
            <PRIORLogo />
          </div>
          <div
            css={css`
              width: 50%;
              float: left;
              @media (max-width: 1150px) {
                display: none;
              }
              margin-top: -10px;
            `}
          >
            <EmailSubscription
              actionIdentifier="1FAIpQLSfNkW16wRNQn1aKiuuP1hBKjv2fhLxPNK632X2BmKqV-KR2Eg"
              entryNumber={457660489}
              inline={true}
              darkBackground={true}
            />
          </div>
          <div
            css={css`
              text-align: right;
              line-height: 16px;
              font-size: 12px;
              margin-top: 10px;
              color: ${color.dark.blue8};
              float: right;

              a {
                color: ${color.dark.blue9};
              }
            `}
          >
            <div>© The Allen Institute for AI - All Rights Reserved</div>
            <a href="//allenai.org/terms.html" target="_blank">
              Terms of Use
            </a>{" "}
            |{" "}
            <a href="//allenai.org/privacy-policy.html" target="_blank">
              Privacy Policy
            </a>
          </div>
        </div>
      </footer>

      {/* statcounter support */}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            var sc_project=11333662; 
            var sc_invisible=1; 
            var sc_security="5ee17f85"; 
            var sc_https=1; 
            var sc_remove_link=1;
          `,
        }}
      />
      <script
        async
        type="text/javascript"
        src="https://www.statcounter.com/counter/counter.js"
      />
      <noscript>
        <div className="statcounter">
          <img
            className="statcounter"
            src="https://c.statcounter.com/12485103/0/e6f008d3/1/"
            alt="Web Analytics"
          />
        </div>
      </noscript>
    </>
  );
}
