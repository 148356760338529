import React, { useState } from "react";
import { Link } from "gatsby";

import { css } from "@emotion/react";
import color from "~styles/color";
import styled from "@emotion/styled";
import { Emoji } from "emoji-mart";
import LinkIcon from "@material-ui/icons/Link";

/**
 * Formats all the section titles on the homepage.
 * @param props.title The name of the section.
 */
export function HomeTitle(props: { title: string }) {
  return (
    <h1
      css={css`
        text-align: center;
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 30px;
      `}
    >
      {props.title}
    </h1>
  );
}

/**
 * headerId is used to convert a header string into its hash
 * (i.e., ai2thor.allenai.org/#header-hash-name).
 *
 * @param header The unformatted header's name (with spaces and all).
 */
export const headerId = (header: string) =>
  header
    .replace(/\s/g, "-")
    .replace(/[^0-9a-zA-Z\-_]/g, "")
    .toLowerCase();

const Header = styled.h1`
  color: ${color.gray10};
  display: inline-block;
  font-size: ${(props: { fontSize: number }) =>
    props.fontSize === undefined ? "" : props.fontSize};

  &:hover {
    cursor: pointer;
  }
`;

function GenericSection(props: {
  sectionTitle: string;
  children: React.ReactNode;
  emoji?: string;
  emojiSize: number;
  prependToHash?: string;
  fontSize?: string;
  hashMargin?: string;
  headerLeftPadding?: string;
}) {
  const defaultHashOpacity = 0,
    hoverHashOpacity = 0.3,
    [hashOpacity, setHashOpacity] = useState(defaultHashOpacity);

  let hash = headerId(String(props.sectionTitle));
  if (props.prependToHash !== undefined) {
    hash = props.prependToHash + hash;
  }

  return (
    <div
      css={css`
        margin-bottom: 25px;
      `}
    >
      <div
        onMouseEnter={() => {
          setHashOpacity(hoverHashOpacity);
        }}
        onMouseLeave={() => {
          setHashOpacity(defaultHashOpacity);
        }}
      >
        <span id={hash} />
        <Link to={"#" + hash}>
          {props.emoji ? (
            <div
              css={css`
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                margin-top: ${props.headerLeftPadding !== undefined
                  ? "-4px"
                  : "-8px"};
              `}
            >
              <Emoji emoji={props.emoji} size={props.emojiSize} />
            </div>
          ) : (
            <></>
          )}
          <Header fontSize={props.fontSize}>{props.sectionTitle}</Header>
          <span
            css={css`
              opacity: ${hashOpacity};
              transition-duration: 0.15s;
              font-weight: normal;
              margin-left: 7px;
              pointer-events: none;
              margin-top: ${props.fontSize === undefined ? "-11px" : "-2px"};
              vertical-align: middle;
              display: inline-block;
              color: black !important;
              font-size: ${props.fontSize === undefined
                ? "2em"
                : props.fontSize};

              &:hover {
                opacity: 0.5;
              }
            `}
          >
            <LinkIcon />
          </span>
        </Link>
      </div>
      {props.children}
    </div>
  );
}

/**
 * Sections can be thought of similar to their LaTeX counterparts,
 * except that the { children } are encompassed inside the body
 * of the section.
 *
 * @param props.sectionTitle is the title of the section.
 * @param props.children is where all the content for the section should be placed.
 * @param props.emoji is the name of the emoji for the section.
 * @param props.tocAlias can be a shortened name added to the table of contents so that
 * the entire section's title (often for long titles) does not have to be added.
 */
export function Section(props: {
  sectionTitle: string;
  children: React.ReactNode;
  emoji?: string;
  tocAlias?: string;
}) {
  return (
    <GenericSection
      sectionTitle={props.sectionTitle}
      children={props.children}
      emoji={props.emoji}
      emojiSize={30}
    />
  );
}

export const PREPEND_TO_SUB_HASH = "sub-";
export function SubSection(props: {
  sectionTitle: string;
  emoji?: string;
  children: React.ReactNode;
  tocAlias?: string;
}) {
  // throw in the sub- for subsections, just in case there's
  // an overlap between a Section and a SubSection
  return (
    <GenericSection
      sectionTitle={props.sectionTitle}
      children={props.children}
      emoji={props.emoji}
      emojiSize={23}
      prependToHash={PREPEND_TO_SUB_HASH}
      fontSize="23px"
      hashMargin="0px"
      headerLeftPadding="41px"
    />
  );
}
