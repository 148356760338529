import React, { useEffect } from "react";

export const DocSearch = (props) => {
  return <></>;
  /*
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    let prevAutocomplete = document.getElementsByClassName(
      "algolia-autocomplete"
    );
    for (let i = 1; i < prevAutocomplete.length; i++) {
      const node = prevAutocomplete[i];
      node.replaceWith(...node.childNodes);
    }

    let docSearched = document.getElementsByClassName("doc-search");
    for (let i = 1; i < docSearched.length; i++) {
      const node = docSearched[i];
      node.remove();
    }

    window.docsearch({
      apiKey: "d7c24fb1c988d548d422c92cd47d554b",
      indexName: "allenai",
      inputSelector: "#doc-search",
      algoliaOptions: { facetFilters: ["project:ithor"] },
    });
  });

  return <></>;
  */
};
