import { Helmet } from "react-helmet";
import React, { useEffect } from "react";

export default function SiteHelmet(props: {
  children: React.ReactNode;
  coverImage?: string;
}) {
  return (
    <Helmet>
      <meta
        property="og:image"
        content={
          props.coverImage
            ? props.coverImage
            : "https://ai2thor.allenai.org/media/social-card.jpg"
        }
      />
      <meta
        name="twitter:image"
        content={
          props.coverImage
            ? props.coverImage
            : "https://ai2thor.allenai.org/media/social-card.jpg"
        }
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:image:width"
        content={props.coverImage ? "1200" : "1280"}
      />
      <meta
        property="twitter:image:height"
        content={props.coverImage ? "630" : "720"}
      />
      <meta
        property="og:image:width"
        content={props.coverImage ? "1200" : "1280"}
      />
      <meta
        property="og:image:height"
        content={props.coverImage ? "630" : "720"}
      />
      <meta name="author" content="Allen Institute for AI" />
      {props.children}
    </Helmet>
  );
}
