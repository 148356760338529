// See: https://github.com/toperkin/staticFormEmails for what's going on!
// It's so cool!!
import React, { useState } from "react";
import { css } from "@emotion/react";
import color from "~styles/color";
import { Emoji } from "emoji-mart";

/**
 * Return true if an email is formatted correctly, otherwise false.
 * Taken from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 * @param email the input email
 */
function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function EmailSubscription(props: {
  actionIdentifier: string;
  entryNumber: number;
  text?: string;
  inline?: boolean;
  darkBackground?: boolean;
  includeEmoji?: boolean;
}) {
  const [submitted, setSubmitted] = useState(false),
    [emailFocused, setEmailFocused] = useState(false),
    [inputEmail, setInputEmail] = useState("");

  const emailIsValid = validateEmail(inputEmail);

  return (
    <>
      <form
        encType="text/plain"
        action={
          emailIsValid
            ? `https://docs.google.com/forms/d/e/${props.actionIdentifier}/formResponse?usp=pp_url&entry.${props.entryNumber}=${inputEmail}`
            : ``
        }
        target={`hidden_iframe${props.actionIdentifier}`}
        onSubmit={() => (emailIsValid ? setSubmitted(true) : false)}
        method="post"
      >
        <div
          css={css`
            margin-bottom: 10px;
            padding-left: ${props.inline ? "0px" : "15px"};
            padding-right: 15px;
          `}
        >
          {props.inline && props.includeEmoji ? (
            <div
              css={css`
                vertical-align: middle;
                display: inline-block;
                margin-top: 7px;
                margin-right: 3px;
              `}
            >
              <Emoji emoji="nerd_face" size={20} />
            </div>
          ) : (
            <></>
          )}
          <div
            css={css`
              font-weight: ${props.inline ? "600" : "bold"};
              font-size: ${props.inline ? "15px" : "25px"};
              color: ${props.darkBackground ? color.gray2 : color.light.blue6};
              vertical-align: middle;
              display: inline-block;
              line-height: ${props.inline ? "20px" : "32.5px"};
              margin-top: ${props.inline ? "5px" : "0px"};
            `}
          >
            {props.text === undefined ? (
              <>Sign Up for Developer Updates</>
            ) : (
              props.text
            )}
          </div>
          {!props.inline && props.includeEmoji ? (
            <div
              css={css`
                vertical-align: middle;
                display: inline-block;
                margin-top: 6px;
                margin-left: 5px;
              `}
            >
              <Emoji emoji="nerd_face" size={32} />
            </div>
          ) : (
            <></>
          )}
        </div>
        {submitted ? (
          <div
            css={css`
              color: ${props.darkBackground ? color.gray3 : color.gray12};
            `}
          >
            Thanks for signing up!
          </div>
        ) : (
          <>
            <div
              css={css`
                border-radius: 5px;
                box-shadow: 0px 0px 2px 0px
                  ${props.inline ? color.light.blue1 : color.light.blue6};
                display: inline-block;
                margin: auto;
                * {
                  padding-top: 3px;
                  padding-bottom: 5px;
                }
              `}
            >
              <input
                type="email"
                autoComplete="off"
                name={`entry.${props.entryNumber}`}
                id={`entry.${props.entryNumber}`}
                onFocus={() => setEmailFocused(true)}
                onBlur={() => setEmailFocused(false)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setInputEmail(event.target.value)
                }
                value={inputEmail}
                placeholder="email"
                css={css`
                  background-color: transparent;
                  transition-duration: 0.3s;
                  box-shadow: 0px 0px 1px 2px
                    ${!emailFocused && !emailIsValid && inputEmail != ""
                      ? color.light.red4
                      : "transparent"};
                  border: none;
                  width: ${props.inline ? "300px" : "350px"};
                  color: ${props.darkBackground ? color.gray3 : color.gray12};
                  border-radius: 5px;
                  padding-left: 8px;
                  @media (max-width: 500px) {
                    width: 200px;
                  }
                `}
              />
              <input
                type={emailIsValid ? "submit" : "button"}
                value="Sign Up"
                onClick={() => (emailIsValid ? true : false)}
                css={css`
                  background-color: transparent;
                  border: none;
                  font-weight: 600;
                  transition-duration: 0.3s;
                  color: ${emailIsValid
                    ? props.darkBackground
                      ? color.light.blue2
                      : color.light.blue6
                    : props.darkBackground
                    ? color.light.blue2 + "88"
                    : color.light.blue6 + "88"};
                  padding-top: 3px;
                  padding-right: 12px;
                  padding-left: 10px;
                  &:hover {
                    cursor: ${emailIsValid ? "pointer" : "default"};
                  }
                `}
              />
            </div>
            <div
              css={css`
                margin-top: 5px;
                color: ${color.gray7};
              `}
            >
              You can unsubscribe at any time.
            </div>
          </>
        )}
      </form>
      <iframe
        name={`hidden_iframe${props.actionIdentifier}`}
        id={`hidden_iframe${props.actionIdentifier}`}
        css={css`
          display: none !important;
        `}
      />
    </>
  );
}
